import React  from 'react'
import FlipPage from 'react-flip-page'
import './Book.css'


const Book = ({flipPageRef, PagesInfo, setPage}) => {
  const screenHeight = window.innerHeight
  const screenWidth = window.innerWidth
  return (
    <FlipPage
          className="flip__page"
          showTouchHint="true" 
          showHint="true"
          width={screenWidth}
          height={screenHeight}
          orientation="horizontal"
          responsive="true"
          flipOnTouch="true"
          disableSwipe={screenWidth < 768 ? true : false} 
          flipOnTouchZone="20"
          animationDuration="700"
          onPageChange={(page) => setPage(page)}
          ref={flipPageRef}
          fullscreen="true"
          >
            {PagesInfo.map((page)=>{
              return(
                  <article key={page.id} className="single__page">
                    <div>
                      <img src={page.image} alt={page.title}/>
                    </div>
                  </article>
              )
            })}
            {/* Final Page */}
              <article className="single__page finalpage">
                  <h2>Descarga el libro Vidas en PDF</h2>
                <a href="https://adndignidad.co/interactivos/vidas.pdf">
                  <button>Descargar PDF</button>
                </a>
                <a href="https://adndignidad.co/">
                  <p>Regresar a <span>ADN Dignidad</span></p>
                </a>
              </article>
        </FlipPage>

   )
}
 
export default Book