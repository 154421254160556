// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
// import SkipNextIcon from '@mui/icons-material/SkipNext'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import {motion} from 'framer-motion'
import HelpModal from './HelpModal'



const Controls = ({page, isPlaying, handlePlay, pagesTitles, PagesInfo, open, handleOpen, flipPageRef, screenAngle, handleDisable}) => {
  
  return ( 
          <div className="controls">
            {/* Play Button */}
            <motion.button className={`${page > 10 && page < 15 ? "play__button" : page > 18 && page < 23 ? "play__button" : "disabled play__button" }`} whileHover={{scale: 1.1}} whileTap={{scale: 0.9}}>
              {isPlaying ? <PauseIcon onClick={handlePlay}/> : <PlayArrowIcon fontSize='small' onClick={handlePlay}/>}
            </motion.button>
            {/* Help */}
            <motion.button className='help__button' whileHover={{scale: 1.1}} whileTap={{scale: 0.9}} onClick={handleOpen}>
              <QuestionMarkIcon fontSize='small'/>
            </motion.button>
            {/* Help */}
            {open ? <HelpModal handleDisable={handleDisable} handleOpen={handleOpen}/> : null}
          </div>
   );
}
 
export default Controls