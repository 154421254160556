import portada from './Portada/portada.jpg'
import portada2 from './Portada/portada1.jpg'
import Intro01 from './Intro/intro1.jpg'
import Intro02 from './Intro/intro2.jpg'
import Cali01 from './Cali/cali6-7.jpg'
import Cali02 from './Cali/cali8-9.jpg'
import Cali03 from './Cali/cali10-11.jpg'
import Cali04 from './Cali/cali12-13.jpg'
import StMarta01 from './SantaMarta/stmarta14-15.jpg'
import StMarta02 from './SantaMarta/stmarta16-17.jpg'
import StMarta03 from './SantaMarta/stmarta18-19.jpg'
import StMarta04 from './SantaMarta/stmarta20-21.jpg'
import Quilla01 from './Barranquilla/quilla22-23.jpg'
import Quilla02 from './Barranquilla/quilla24-25.jpg'
import Quilla03 from './Barranquilla/quilla26-27.jpg'
import Quilla04 from './Barranquilla/quilla28-29.jpg'
import Maicao01 from './Maicao/maicao30-31.jpg'
import Maicao02 from './Maicao/maicao32-33.jpg'
import Maicao03 from './Maicao/maicao34-35.jpg'
import Maicao04 from './Maicao/maicao36-37.jpg'
import Ipiales01 from './Ipiales/ipiales38-39.jpg'
import Ipiales02 from './Ipiales/ipiales40-41.jpg'
import Ipiales03 from './Ipiales/ipiales42-43.jpg'
import Ipiales04 from './Ipiales/ipiales44-45.jpg'
import Bogota01 from './Bogota/bogota46-47.jpg'
import Bogota02 from './Bogota/bogota48-49.jpg'
import Bogota03 from './Bogota/bogota50-51.jpg'
import Bogota04 from './Bogota/bogota52-53.jpg'
import Contraportada from './Portada/contraportada.jpg'

const PagesInfo = [
  //Portada
    {
      id: 0,
      title: "Portada",
      image: portada,
      mobile: portada2,
    },
    //Creditos Intro
    {
      id: 1,
      title: "Créditos",
      image: Intro01,
    },
    {
      id: 2,
      title: "Introducción",
      image: Intro02,
    },
    //Capitulo 1
    {
      id: 3,
      title: "Capitulo 1",
      image: Cali01,
    },
    {
      id: 4,
      title: "Capitulo 1",
      image: Cali02,
    },
    {
      id: 5,
      title: "Capitulo 1",
      image: Cali03,
    },
    {
      id: 6,
      title: "Capitulo 1",
      image: Cali04,
    },
    //Capitulo 2
    {
      id: 7,
      title: "Capitulo 2",
      image: StMarta01,
    },
    {
      id: 8,
      title: "Capitulo 2",
      image: StMarta02,
    },
    {
      id: 9,
      title: "Capitulo 2",
      image: StMarta03,
    },
    {
      id: 10,
      title: "Capitulo 2",
      image: StMarta04,
    },
    //Capitulo 3
    {
      id: 11,
      title: "Capitulo 3",
      image: Quilla01,
    },
    {
      id: 12,
      title: "Capitulo 3",
      image: Quilla02,
    },
    {
      id: 13,
      title: "Capitulo 3",
      image: Quilla03,
    },
    {
      id: 14,
      title: "Capitulo 3",
      image: Quilla04,
    },
    //Capitulo 4
    {
      id: 15,
      title: "Capitulo 4",
      image: Maicao01,
    },
    {
      id: 16,
      title: "Capitulo 4",
      image: Maicao02,
    },
    {
      id: 17,
      title: "Capitulo 4",
      image: Maicao03,
    },
    {
      id: 18,
      title: "Capitulo 4",
      image: Maicao04,
    },
    //Capitulo 5
    {
      id: 19,
      title: "Capitulo 5",
      image: Ipiales01,
    },
    {
      id: 20,
      title: "Capitulo 5",
      image: Ipiales02,
    },
    {
      id: 21,
      title: "Capitulo 5",
      image: Ipiales03,
    },
    {
      id: 22,
      title: "Capitulo 5",
      image: Ipiales04,
    },
    //Capitulo 6
    {
      id: 23,
      title: "Capitulo 6",
      image: Bogota01,
    },
    {
      id: 24,
      title: "Capitulo 6",
      image: Bogota02,
    },
    {
      id: 25,
      title: "Capitulo 6",
      image: Bogota03,
    },
    {
      id: 26,
      title: "Capitulo 6",
      image: Bogota04,
    },
    //Final
    {
      id: 27,
      title: "Final",
      image: Contraportada,
    }
  ]
 
export default PagesInfo
