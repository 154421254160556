import './HelpModal.css'
import HelpInfo from '../pages/Help/HelpInfo.png'
import HelpInfoLaptop from '../pages/Help/helpInfoLaptop.png'
//import {motion} from 'framer-motion'

const HelpModal = ({handleOpen, handleDisable}) => {
  const screenWidth = window.innerWidth
  const handleOpenClick = (e) => {
    handleOpen(e)
    handleDisable()

  }
  return (
          <div className="help__container">
            <div
              onClick={handleOpenClick} 
              className="help">
              <div className="help__close">
                <p>Cerrar X</p>
              </div>
              <h2>Instrucciones de <br></br> Navegación</h2>
              <img src={screenWidth > '1080' ? HelpInfoLaptop : HelpInfo} alt="HelpInfo"/>
            </div>
          </div>
          
   );
}
 
export default HelpModal