import './App.css'
import React, { useCallback, useEffect, useRef, useState }  from 'react'
import PagesInfo from './pages/PagesInfo'
//import VolumeUpIcon from '@mui/icons-material/VolumeUp'
//import VolumeOffIcon from '@mui/icons-material/VolumeOff'
//import FinalPage from './pages/FinalPage'
import Book from './components/Book'
import Controls from './components/Controls'
import HelpModal from './components/HelpModal'
import BackgroundImage from './components/BackgroundImage'



function App() {

  const [page, setPage] = useState(0)
  //const [mute , setMute] = useState(false)
  const [isPlaying, setIsPlaying] = useState(null)
  
  const audioRef = useRef(null)
  const flipPageRef = useRef(null)

  //chapters depend on the page
  const frontPage = page === 0 ? "Portada" : ""
  const credits = page === 1 ? "Créditos" : ""
  const intro = page > 1 && page < 3 ? "Introducción" : ""
  const chapter1 = page > 2 && page < 7 ? "Capítulo 1" : ""
  const chapter2 = page > 6 && page < 11 ? "Capítulo 2" : ""
  const chapter3 = page > 10 && page < 15 ? "Capítulo 3 (Audio)" : ""
  const chapter4 = page > 14 && page < 19 ? "Capítulo 4" : ""
  const chapter5 = page > 18 && page < 23 ? "Capítulo 5 (Audio)" : ""
  const chapter6 = page > 22 && page < 27 ? "Capítulo 6" : ""
  const finalPage = page === 27 ? "Descarga" : ""
  const pagesTitles = [frontPage, credits, intro, chapter1, chapter2, chapter3, chapter4, chapter5, chapter6, finalPage]

  //Play Audio
  const handlePlay = () => {
    const audiotag = document.querySelectorAll("video, audio")
    if(isPlaying){
      audiotag.forEach(audio => {
        audio.pause()
      })
    }
    else{
      audiotag.forEach(audio => {
        audio.play()
      })
    }
    setIsPlaying(!isPlaying)
  }

  //Key arrows to navigate
  const nextPage = useCallback((event) => {
    if(event.keyCode === 39) {
        flipPageRef.current.gotoNextPage()
      }
    }, []);

    const prevPage = useCallback((event) => {
      if(event.keyCode === 37) {
          flipPageRef.current.gotoPreviousPage()
        }
      }, []);

    //useeffect to detect key press
    useEffect(() => {
      document.addEventListener('keydown', nextPage)
      document.addEventListener('keydown', prevPage)
      return () => {
        document.removeEventListener('keydown', nextPage)
        document.removeEventListener('keydown', prevPage)
      }
    } , [nextPage, prevPage])


    //Detect if audio is playing or not
    useEffect(()=>{
      const audioTagHtml = audioRef.current
        //Get if audio is paused or not
        if(audioTagHtml){
          if(audioTagHtml.paused){
            setIsPlaying(false)
          }
          else{
            setIsPlaying(true)
          }
        }
    }, [page])


  //Modal to show the help
  const [open, setOpen] = useState(true)
  const handleOpen = () => {
    setOpen(!open)
    handleDisable()
  }

  const [disableBook, setDisableBook] = useState(true)

  const handleDisable = () => {
    setDisableBook(false)
  }

  return (
    <>
      <div className="App">
        {disableBook ? <BackgroundImage/> : <Book flipPageRef={flipPageRef} setPage={setPage} PagesInfo={PagesInfo}/> }
        <div className="popup">
          <h1>ADN</h1>
        </div>
      </div>
      {/* Controls */}
      <Controls
        page={page}
        isPlaying={isPlaying}
        handleOpen={handleOpen} 
        handlePlay={handlePlay} 
        pagesTitles={pagesTitles}
        PagesInfo={PagesInfo}
        open={open}
        flipPageRef={flipPageRef}
        handleDisable={handleDisable}

      />
      {/* Sound Icon to click and lister audio */}
      {page > 10 && page < 15 ? <audio ref={audioRef} hidden src="https://adndignidad.co/audios/solmayra.mp3"s loop/> : null}
      {page > 18 && page < 23  ? <audio ref={audioRef} hidden src="https://adndignidad.co/audios/lisbeth.mp3" loop/> : null}
    </>

  );
}

export default App
