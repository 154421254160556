import BgImage from '../pages/Portada/portada.jpg'

const BackgroundImage = () => {
  return (
    <>
      <div className="bg__container">
        <img src={BgImage} alt="bg"/>
      </div>
      <style jsx>{`
        .bg__container {
          width: 100%;
          height: 100%;
          display: flex ;
          justify-content: center ;
          align-items: center;
        }

        .bg__container img {
          max-width: 100%;
        }
        `}</style>
    </>
   )
}
 
export default BackgroundImage